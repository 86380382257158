@import '../../styles/Vars';


.error404 {
    color: darken($primary-color, 10);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 288px;
        margin: 20px 0;

        @media screen and (max-width: 1000px) {
            font-size: 96px;
        }
    }

    h2 {
        font-size: 36px;
        margin: 80px 0;
        text-align: center;

        @media screen and (max-width: 1000px) {
            font-size: 18px;
        }
    }

    a {
        margin: 60px 0;
        font-size: 18px;
        text-decoration: underline;
        color: $primary-color;

        @media screen and (max-width: 1000px) {
            font-size: 14px;
        }

        &:hover {
            color: darken($primary-color, 25%);
        }
    }
}