.footer {
    background-color: black;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-logo {
    filter: brightness(0%) invert(100%);
    height: 40px;
}

.footer-text {
    color: #eeeeee;
}

.insta {
    mask-image: url(../../img/insta.svg);
    -webkit-mask-image: url(../../img/insta.svg);
    height: 32px;
    width: 32px;
    background-color: white;
    position: relative;
    display: block;

    &:hover {
        &::after {
            transform: translate(0, 0);
            opacity: 1;
            transition: transform .4s ease-in-out,
                opacity .6s ease-in-out;
        }
    }

    &::after {
        content: url(../../img/backgroundInsta.svg);
        height: 32px;
        width: 32px;
        position: absolute;
        transform: translate(-32px, 32px);
        transition: transform .4s ease-in-out,
            opacity .6s ease-in-out;
        opacity: 0;
    }
}