@import '../../styles/Vars';

.card {
    height: auto;
    width: 47%;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius;

    @media screen and (max-width: 900px) {
        width: 100%;
    }

    .card-img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        transition: all .3s ease-in-out;
    }

    &:hover {
        .card-img {
            scale: 1.05;
        }
    }

    .title {
        position: absolute;
        z-index: 1;
        color: white;
        width: 70%;
        bottom: 20px;
        left: 20px;
        font-size: 18px;
    }

    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, .9) 0, rgba(2, 14, 18, 0) 50%);
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: $border-radius;
    }
}