@import '../../styles/Vars';


.thumbnail {
    max-height: 50vh;
    min-height: 180px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        max-height: unset;
        min-height: unset;
        width: 100%;
        height: auto;
    }
}

.thumbnailInsta {
    max-height: 50vh;
    min-height: 180px;
    cursor: pointer;
}

.top-part {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        margin-top: 20px;
    }
}

.main-video {
    background-color: #1a1a1a;
    padding: 50px 40px;

    @media screen and (max-width: 900px) {
        padding: 10px 10px;
    }
}

.video-text {
    text-align: center;
    margin-bottom: 50px;

    @media screen and (max-width: 900px) {
        margin-bottom: 20px;
    }
}

.video-title {
    margin-top: 0;
    color: #eeeeee;
}

.video-description {
    color: #c5c5c5;
    font-size: 20px;
    white-space: pre-line;
    line-height: 1.4em;

    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
}

.video-img {
    height: 600px;
    display: flex;
    margin: auto;

    @media screen and (max-width: 900px) {
        height: unset;
        width: 100%;
    }
}

.video-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 900px) {
        gap: 20px;
    }
}


.image-gallery {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 1300px;
    margin: 0 auto;

    @media screen and (max-width: 1234px) {
        display: flex;
        flex-wrap: wrap;
        /* Permet de passer à la ligne pour les éléments excédentaires */
        gap: 20px;
        /* Espacement entre les éléments */
        justify-content: space-between;
        /* Espace entre les colonnes */
    }

    @media screen and (max-width: 624px) {
        justify-content: center;
    }
}


.image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 284px;

    @media screen and (max-width: 1234px) {
        flex: 1 1 calc(50% - 20px);
        /* Chaque élément prend 50% de la largeur moins l'espace */
        box-sizing: border-box;
        /* Inclure le padding et la bordure dans la taille */
        max-width: calc(50% - 20px);
        /* Assurer que la largeur maximale reste à 50% */
    }
}

@media screen and (max-width: 1250px) {

    .display-unset {
        display: unset;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        margin-bottom: 30px;
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.button {
    display: flex;
    justify-content: center;
    margin: 60px auto 10px auto;
    padding: 15px 25px;
    border-radius: 40px;
    width: fit-content;
    background-color: white;
    color: black;
    font-weight: 600;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: rgb(212, 212, 212);
    }

    @media screen and (max-width: 900px) {
        margin: 20px auto 10px auto;
        font-size: 14px;
    }
}

.title-part {
    color: $primary-color;

    h1 {
        font-size: 36px;
        font-weight: 500;
        margin-top: 0px;
        margin-bottom: 30px;

        @media screen and (max-width: 900px) {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    span {
        font-size: 18px;

        @media screen and (max-width: 900px) {
            font-size: 14px;
        }
    }
}

.profile-part {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 900px) {
        margin-bottom: 0px;
        gap: 10px;
    }
}

.profile-pic {
    border-radius: 100px;
    height: 65px;

    @media screen and (max-width: 900px) {
        height: 45px;
    }
}

.profile-name {
    color: $primary-color;
    font-size: 18px;
    text-align: right;
    width: min-content;

    @media screen and (max-width: 900px) {
        font-size: 14px;
    }
}

@media screen and (max-width: 900px) {
    .profile {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 20px;
    }
}

.accordion-50 {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
        display: unset;
    }

    .accordion {
        width: 46%;

        @media screen and (max-width: 900px) {
            width: 100%;
        }
    }

    .accordion-content {
        padding: 30px 20px;
    }

}