.banner {
    position: relative;
    display: flex;

    .banner-img,
    .banner-video {
        height: calc(100vh - 92px);
        width: 100%;
        object-fit: cover;

        // @media screen and (max-width: 900px) {
        //     height: 300px;
        // }
    }

    .photo-drone {
        object-position: bottom;
    }

    .banner-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        z-index: 1;
        width: 80vw;


        .banner-title {
            font-weight: 500;
            color: white;
            font-size: 48px;
            text-align: center;

            @media screen and (max-width: 1300px) {
                font-size: 32px;
            }
        }

        .banner-subTitle {
            font-size: 20px;
            text-align: center;
            color: lightgray;
        }
    }
}