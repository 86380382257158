@import '../../styles/Vars';

.block-cards {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 40px;
    gap: 50px;
    background-color: #1a1a1a;
    justify-content: center;

    @media screen and (max-width: 750px) {
        padding: 50px 10px;
        gap: 40px;
    }
}