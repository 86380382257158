.skeleton {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    background-color: #1a1a1a;
    justify-content: center;
}

.skeleton-card {
    display: flex;
    flex-wrap: wrap;
}

.skeleton-img {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    /* 16:9 aspect ratio (9 / 16 * 100) */
    background-color: #555;
    /* Lighter background color */
    animation: loadingAnimation 1.5s infinite ease-in-out alternate;
}

.skeleton-text {
    width: 100%;
    height: 20px;
    background-color: #666;
    /* Lighter background color */
    margin-top: 10px;
    /* Adjust as needed */
    animation: loadingAnimation .5s infinite ease-in-out alternate;
    width: 45% !important;
    bottom: 35px !important;
}

@keyframes loadingAnimation {
    0% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}