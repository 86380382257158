@import '../../styles/Vars';

nav {
    padding: 20px 50px;
    display: flex;
    align-items: center;
    background-color: $primary-color;

    @media screen and (max-width: 900px) {
        padding: 30px 20px;
    }

    display: flex;
    justify-content: space-between;

    .logo-span {
        color: white;
        font-size: 25px;

        @media screen and (max-width: 900px) {
            font-size: 20px;
        }

        @media screen and (max-width: 750px) {
            content: url(../../img/logo.png);
            height: 65px;
            position: absolute;
            top: 12px;
        }

        @media screen and (max-width: 550px) {
            display: none;

        }
    }

    img {
        height: 40px;

        @media screen and (max-width: 900px) {
            height: 30px;
        }
    }

    .logo {
        width: fit-content;
        display: flex;
        justify-content: center;
    }

    .menu,
    .social {
        width: 30%;
    }

    .social {
        display: flex;
        justify-content: flex-end;
    }

    .insta {
        mask-image: url(../../img/insta.svg);
        -webkit-mask-image: url(../../img/insta.svg);
        height: 32px;
        width: 32px;
        background-color: white;
        position: relative;
        display: block;

        &:hover {
            &::after {
                transform: translate(0, 0);
                opacity: 1;
                transition: transform .4s ease-in-out,
                    opacity .6s ease-in-out;
            }
        }

        &::after {
            content: url(../../img/backgroundInsta.svg);
            height: 32px;
            width: 32px;
            position: absolute;
            transform: translate(-32px, 32px);
            transition: transform .4s ease-in-out,
                opacity .6s ease-in-out;
            opacity: 0;
        }
    }

    ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        gap: 50px;

        @media screen and (max-width: 750px) {
            gap: 30px;
        }

        a {
            text-decoration: none;
            color: white;
            position: relative;
            font-size: 20px;

            @media screen and (max-width: 900px) {
                font-size: 16px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -4px;
                width: 0;
                height: 2px;
                background-color: white;
                transition: width 0.4s ease-in-out;
            }

            &:hover::after,
            &:focus::after {
                width: 100%;
            }
        }
    }
}