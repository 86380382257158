body {
    font-family: 'Montserrat',
        sans-serif;
    background-color: black;
    margin: 0;
}

ul {
    margin-block: 0;
    margin-inline: 0;
    padding-inline: 0;
}

a {
    text-decoration: none;
}