@import '../../styles/Vars';

.block-photo {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.instaImg {
    max-width: 100%;
}

.card-photo {
    max-height: 240px;
    min-height: 180px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        max-height: unset;
        min-height: unset;
        width: 100%;
        height: auto;
    }
}

.js-pic-full-height {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    transform: translateY(-100vh);
    transition: 0s .05s all ease-in-out;
}

.active-js-pic-full-height {
    opacity: 1;
    transform: translateY(0);
}

.pic-full-height {
    height: 100vh;

    @media screen and (max-width: 900px) {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}

.close {
    position: absolute;
    right: 50px;
    top: 50px;
    width: 50px;
    height: 50px;
    opacity: 0.6;
    cursor: pointer;
    transition: all .2s ease-in-out;

    @media screen and (max-width: 900px) {
        scale: 0.5;
        right: 5px;
        top: 5px;
    }

    &:hover {
        opacity: 1;
    }

    &:before,
    &:after {
        position: absolute;
        left: 24px;
        content: ' ';
        height: 50px;
        width: 2px;
        background-color: white;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.swiperPrev,
.swiperNext {
    content: url(../../img/swiper.svg);
    position: fixed;
    top: 50%;
    cursor: pointer;
    opacity: 0.6;
    transition: all .2s ease-in-out;

    &:hover {
        opacity: 1;
    }

    @media screen and (max-width: 900px) {
        top: unset;
        bottom: -15px;
        scale: 0.3;
    }
}

.swiperPrev {
    left: 25px;
}

.swiperNext {
    right: 25px;
    scale: -1;

    @media screen and (max-width: 900px) {
        scale: -0.3;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}