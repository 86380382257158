.main-photo {
    background-color: #1a1a1a;
    padding: 50px 40px;

    @media screen and (max-width: 750px) {
        padding: 20px 10px;
    }
}

.photo-text {
    text-align: center;
    margin-bottom: 50px;
}

.photo-title {
    margin-top: 0;
    color: #eeeeee;
}

.photo-description {
    color: #888888;
    font-size: 20px;
}